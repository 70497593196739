import { ActionType, Session, SessionAction, SessionEnv } from "./session.model";

const initialState: Session = {
  authStateHasLoaded: false,
  isLoggingOut: false,
  isVerifying: false,
  isAuthorized: false,
  verification: null,
  isSignup: false,
  showAlert: true,
  env: null,
  bookingRolloutMsas: [],
  popups: {},
  referralRate: "",
  isFetchAgentProfileFailureAlertEnabled: false,
  loggedNCIconViewedEvent: false,
};

const session = (state = initialState, action: SessionAction): Session => {
  switch (action.type) {
    case ActionType.VERIFICATION_ID_RECEIVED:
      return {
        ...state,
        verification: {
          phone: action.data?.["phone"],
        },
      };

    case ActionType.VERIFICATION_REQUESTED:
      return {
        ...state,
        isVerifying: true,
      };

    case ActionType.SET_IS_VERIFYING_DONE:
      return {
        ...state,
        isVerifying: false,
      };

    case ActionType.LOGGED_IN:
      return {
        ...state,
        authStateHasLoaded: true,
        isVerifying: false,
        verification: null,
        isAuthorized: true,
        userId: action.data?.userId,
        profile: action.data?.profile,
        sendBirdAccessToken: action.data?.sendBirdAccessToken,
      };

    case ActionType.AGENT_PROFILE:
      return {
        ...state,
        agent: {
          ...state.agent,
          ...action.data?.agent,
        },
        userId: action.data?.agent?.userId ?? state.userId,
      };

    case ActionType.UPDATE_AGENT:
      return {
        ...state,
        agent: {
          ...state.agent,
          ...action.data?.agent,
        },
      };

    case ActionType.LOGOUT_REQUESTED:
      return {
        ...state,
        isLoggingOut: true,
      };

    case ActionType.LOGGED_OUT:
      return {
        ...state,
        agent: undefined,
        userId: undefined,
        authStateHasLoaded: true,
        isLoggingOut: false,
        isAuthorized: false,
        isSignup: false,
        env: null,
      };

    case ActionType.SET_IS_SIGNUP:
      return {
        ...state,
        isSignup: action.data?.isSignup ?? true,
      };

    case ActionType.SET_SHOW_ALERT:
      return {
        ...state,
        showAlert: false,
      };

    case ActionType.SET_ENV_VARIABLES: {
      const env = Object.assign({}, action.data) as SessionEnv;
      return {
        ...state,
        env,
      };
    }

    case ActionType.SHOW_IC_AGREEMENT_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          ICAgreement: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_IC_AGREEMENT_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          ICAgreement: { visible: false, ...action.data },
        },
      };
    case ActionType.SHOW_UPLOAD_TIMESHEET_MODAL:
      return {
        ...state,
        popups: {
          ...state.popups,
          uploadTimesheetModal: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_UPLOAD_TIMESHEET_MODAL:
      return {
        ...state,
        popups: {
          ...state.popups,
          uploadTimesheetModal: { visible: false },
        },
      };
    case ActionType.SHOW_NEED_ACCESS_TO_TOAST:
      return {
        ...state,
        popups: {
          ...state.popups,
          userDeniedAccessTo: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_NEED_ACCESS_TO_TOAST:
      return {
        ...state,
        popups: {
          ...state.popups,
          userDeniedAccessTo: { visible: false, ...action.data },
        },
      };
    case ActionType.SHOW_DEEP_LINK_WWF_CANNOT_ADD_FRIEND_ALERT:
      return {
        ...state,
        popups: {
          ...state.popups,
          cantAddFriendPopup: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_DEEP_LINK_WWF_CANNOT_ADD_FRIEND_ALERT:
      return {
        ...state,
        popups: {
          ...state.popups,
          cantAddFriendPopup: { visible: false, ...action.data },
        },
      };
    case ActionType.UPDATE_REFERRAL_RATE:
      return {
        ...state,
        referralRate: action.data?.referralRate,
      };
    case ActionType.SHOW_PROFILE_UPDATE_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          profileUpdatePopup: { visible: true, ...action.data },
        },
      };
    case ActionType.HIDE_PROFILE_UPDATE_POPUP:
      return {
        ...state,
        popups: { ...state.popups, profileUpdatePopup: { visible: false } },
      };
    case ActionType.SHOW_FETCH_WORKER_FAILURE_ALERT:
      return {
        ...state,
        isFetchAgentProfileFailureAlertEnabled: action.data?.isFetchAgentProfileFailureAlertEnabled,
      };
    case ActionType.SET_RECOGNIZED_HOLIDAY:
      return {
        ...state,
        recognizedHoliday: action.data?.recognizedHoliday ?? state.recognizedHoliday,
      };
    case ActionType.LOG_NC_ICON_VIEWED_EVENT:
      return {
        ...state,
        loggedNCIconViewedEvent: action.data?.loggedNCIconViewedEvent,
      };
    default:
      return state;
  }
};

export { session };
